<template>
  <ion-page>
    <!-- header -->
    <ion-header translucent>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <!-- content -->
    <ion-content fullscreen>
      <div id="container">
        <section>
          <div class="lnw_logo"></div>
          <template v-if="isCheckSessionYet">
            <ion-button fill="solid" @click="loginWithLnwaccounts">
              Sign in with LnwAccounts
            </ion-button>
          </template>
          <template v-else>
            <div>
              <ion-text>Authenticating</ion-text>
            </div>
            <ion-spinner name="dots"></ion-spinner>
          </template>
        </section>
      </div>
    </ion-content>

    <!-- -->
  </ion-page>
</template>

<script>
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSpinner,
  IonText,
} from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Login",
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSpinner,
    IonText,
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      isCheckSessionYet: "auth/isCheckSessionYet",
    }),
    title() {
      if (this.isCheckSessionYet) {
        return "Sign in";
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions({
      redirectToLogin: "auth/redirectToLnwAccount",
    }),
    loginWithLnwaccounts() {
      this.redirectToLogin();
    },
  },
  watch: {
    isLoggedIn(oldValue, newValue) {
      if (!oldValue && newValue) {
        this.$router.push({ name: "home" });
      }
    },
  },
};
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 30%;
  transform: translateY(-50%);
}
#container .lnw_logo {
  width: 160px;
  height: 50px;
  margin: 0 auto;
  margin-bottom: 2em;
  background-image: url("../img/lnw_logo.png");
  background-repeat: no-repeat;
}
</style>
